<template>
  <LayoutHome>
    <!--Banner-->
    <div class="banner note" :style="{ backgroundImage: 'url(' + setTeacherBackground + ')' }">
      <h3 class="main-title">{{ setTeacherName }}</h3>
      <h4 class="sub-title">{{ setTeacherDescription }}</h4>
    </div>
    <!--List-->
    <div class="teacher-wrapper">
      <el-row class="teacher-list" :gutter="8">
          <el-col class="list-show" v-for="(teacher, i) in teacherList" :key="i" :span="8">
            <div class="list-show-info">
              <div class="info-base">
                <div class="left">
                  <el-tooltip effect="dark" :content="teacher.name" placement="top-start">
                    <b>{{ teacher.name }}</b>
                  </el-tooltip>
                  <p class="introduce" style="margin-top: 8px">
                    {{ teacher.vassalage }}
                  </p>
                  <p class="introduce">{{ teacher.typeDescription }}</p>
                </div>
                <img class="teacher-avatar" v-if="teacher.portraitAttachmentUrl" :src="teacher.portraitAttachmentUrl" />
              </div>
              <div class="line"></div>
              <div class="detail">
                <el-tooltip effect="dark" :content="teacher.introduce ? teacher.introduce.replace(/<.*?>/gi, '') : ''" placement="top-start">
                <div class="text">{{teacher.introduce ? teacher.introduce.replace(/<.*?>/gi, "") : ""}}</div>
                </el-tooltip>
                <a @click="toDetail(teacher.id)" class="detail-link">查看详情<i class="el-icon-arrow-right"></i></a>
              </div>
            </div>
          </el-col>
      </el-row>
      <div class="page">
        <span @click="handlePageChange(currentPage - 1)" class="prev" :class="{ disable: 1 === currentPage }">上一页</span>
        <span @click="handlePageChange(i)" class="num" :class="{ active: i === currentPage }" v-for="i in totalPages" :key="i">{{ i }}</span>
        <span @click="handlePageChange(currentPage + 1)" class="next" :class="{ disable: currentPage === totalPages }">下一页</span>
      </div>
    </div>
  </LayoutHome>
</template>
<script>
import LayoutHome from "./../../components/Layout";
import Time from "./../../plugins/date";
import { getCookie,setCookie,removeCookie,getLoginUUId,getLoginUserId,getLoginRealName,getLoginUserName,getLoginUserUrl } from "../../plugins/cookie";
export default {
  components: {
    LayoutHome,
  },
  data() {
    return {
      formInline: {
        pageSize: 8,
        pageNum: 1,
      },
      teacherList: [],
      totalElements: 0,
      totalPages: 0,
      currentPage: 1,
      setTeacherName: "",
      setTeacherDescription: "",
      setTeacherBackground: "",
      setFindTeacherId: "195EBCB485724A558E6E95DA8C3A4CD7",
    };
  },
  created() {
    this.getTeacherList();
    this.getTeacherSet();
  },
  methods: {
    async getSchoolEntity() {
      let school = await this.$get(`/admin/getSchoolByContext/${this.$route.params.context}`);
      return school;
    },
    async getTeacherSet() {
      const teacherDate = await this.$get("/school-teacher/findList/foreign", {
        foreign: "schoolId",
        value: (await this.getSchoolEntity()).id,
      });
      this.setTeacherName = teacherDate[0].name;
      this.setTeacherDescription = teacherDate[0].description;
      this.setTeacherBackground = teacherDate[0].backgroundAttachmentUrl;
    },
    toDetail(id) {
      this.$router.push(`/${this.$route.params.context}/teacherview/${id}`);
    },
    handlePageChange(pageNum) {
      if (pageNum < 1 || pageNum > this.totalPages) return;
      this.getTeacherList({ pageNum });
    },
    async getTeacherList(data = {}) {
      const params = { ...this.formInline, ...data };
      const teacherData = await this.$get("/teacher/findPage", params);
      this.teacherList = teacherData.content;
      this.newsTotal = teacherData.totalElements;
      this.totalPages = teacherData.totalPages;
      this.currentPage = params.pageNum;
    },
  },
};
</script>
<style scoped>
.banner {
  background-repeat: no-repeat;
  background-position: center bottom;
  height: 270px;
  padding: 94px 0 0;
}
.teacher-wrapper {
  width: 1200px;
  margin: 0 auto;
  border: solid 1px #eee;
  padding: 10px 20px;
  position: relative;
  top: -52px;
  background: #fff;
  border-radius: 8px;
}

.main-title {
  font-size: 32px;
  font-weight: bold;
  color: #fff;
  line-height: 46px;
  letter-spacing: 1px;
  text-align: center;
  margin: 0 0 5px 0;
}
.sub-title {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  line-height: 29px;
  text-align: center;
  margin: 0;
}
.list-show {
  padding: 10px;
}
.list-show-info {
  border: solid 1px #e9eff6;
  border-top: solid 4px #5676dc;
  padding: 36px 20px;
}
.info-base {
  display: flex;
  justify-content: space-between;
}
.info-base b {
  font-size: 20px;
  font-weight: 600;
  color: #2a2a2a;
  line-height: 28px;
  margin-bottom: 8px;
}
.info-base .left {
  flex-grow: 1;
}
.info-base .introduce {
  font-style: normal;
  font-size: 12px;
  font-weight: 400;
  color: #999;
  line-height: 17px;
  margin: 0;
}
.info-base .teacher-avatar {
  width: 84px;
  height: 84px;
  border-radius: 50%;
}
.list-show-info .line {
  border-top: solid 1px #e9eff6;
  margin: 15px 0 26px;
}
.list-show-info .detail .text {
  font-size: 12px;
  font-weight: 400;
  color: #616161;
  line-height: 20px;
  height: 40px;
  margin-bottom: 18px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.detail-link {
  font-size: 12px;
  font-weight: 400;
  color: #3d84ff;
  line-height: 17px;
  cursor: pointer;
}
</style>
<style>
.el-tooltip__popper {
  max-width: 400px;
  line-height: 180%;
}
</style>