<template>
  <LayoutHome>
    <div class="bread-crumb">您当前所在的位置 ：名师风采 > 名师详情</div>
    <div class="teacher-detail">
      <div class="base-info">
        <div class="left">
          <div class="name">
            <span>{{ teacherInfo.name }}</span>
            <span class="pinyin">{{
              convertPinyin(teacherInfo.name || "")
            }}</span>
          </div>
        </div>
        <img
          class="teacher-avatar"
          v-if="teacherInfo.portraitAttachmentUrl"
          :src="teacherInfo.portraitAttachmentUrl"
        />
      </div>
      <div class="content">
        <div v-html="teacherInfo.introduce || '暂无数据'"></div>
      </div>
    </div>
  </LayoutHome>
</template>
<script>
import LayoutHome from "./../../components/Layout";
import { ConvertPinyin } from "./../../plugins/util";
export default {
  components: {
    LayoutHome,
  },
  data() {
    return {
      teacherInfo: [],
      id: this.$route.params.id,
    };
  },
  created() {
    this.getTeacherInfo();
  },
  methods: {
    async getTeacherInfo() {
      const teacherInfo = await this.$get(`/teacher/findOne/${this.id}`);
      this.teacherInfo = teacherInfo;
      // console.log(teacherInfo);
    },
    convertPinyin: ConvertPinyin,
  },
};
</script>
<style scoped>
.bread-crumb {
  width: 1200px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
  margin: 30px auto 16px;
}
.teacher-detail {
  border-top: solid 4px #5676dc;
  background: #fff;
  width: 1200px;
  margin: 0 auto;
  padding: 44px 163px;
}
.teacher-detail .name {
  font-size: 26px;
  line-height: 37px;
  color: #2a2a2a;
}
.teacher-detail .name .pinyin {
  margin-left: 12px;
  font-size: 12px;
  font-weight: 400;
  color: #616161;
  line-height: 17px;
  letter-spacing: 1px;
}
.base-info {
  display: flex;
  justify-content: space-between;
}
.base-info .left {
  flex-grow: 1;
}
.base-info .teacher-avatar {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  float: right;
}
.content {
  border-top: solid 1px #eee;
  margin: 33px 0 0 0;
}
</style>